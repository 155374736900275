import { customType } from '@doltech/pageManagement/customDTO';

import { apis } from '../../api/api.config';
import { apiUrls } from '../../api/api.urls';

interface RequestOptDTO {
  phone: string;
}

interface VerifyOptDTO {
  phone: string;
  code: string;
}

interface PurchasesAppDTO {
  app: 'DOLVN';
}

export const requestOtp = async (requestBody: RequestOptDTO) => {
  const response = await apis.verifyToken.post<customType['ResponseEntity']>(
    apiUrls.verifyToken.REQUEST_OTP,
    requestBody
  );
  return response.data;
};

export const verifyOtp = async (requestBody: VerifyOptDTO) => {
  const response = await apis.verifyToken.post<customType['ResponseEntity']>(
    apiUrls.verifyToken.VERIFY_OTP,
    requestBody
  );
  return response.data;
};

export const purchasesApp = async (purchasesAppDTO: PurchasesAppDTO) => {
  const response = await apis.userService.post<customType['ResponseEntity']>(
    apiUrls.userService.UPDATE_PURCHASES,
    null,
    {
      params: {
        app: purchasesAppDTO.app,
      },
    }
  );
  return response.data;
};

export const linkUser = async () => {
  return apis.userService.post<customType['ResponseEntity']>(apiUrls.userService.LINK_USER, null);
};

export const fetchUserIdentities = async () => {
  const response = await apis.userService.get<any>(apiUrls.userService.USER_IDENTITIES);
  return response.data;
};

export const linkUserById = async ({ primaryUserId, subUserId }) => {
  return apis.userService.post<customType['ResponseEntity']>(apiUrls.userService.LINK_USER_BY_ID, {
    primaryUserId,
    subUserId,
  });
};

export const unlinkUserById = async ({ primaryUserId, subUserId }) => {
  return apis.userService.post<customType['ResponseEntity']>(
    apiUrls.userService.UNLINK_USER_BY_ID,
    {
      primaryUserId,
      subUserId,
    }
  );
};
